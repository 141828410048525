import React from 'react';
import { Person } from '@packages/common';
import { BadgesService } from '../services/BadgesService';
import useFetch from './useFetch';

const badgesService = new BadgesService();

export interface PeopleHook {
  isLoaded: boolean;
  people: Person[];
}

export default function usePeople(): PeopleHook {
  const { isLoaded, data: people, loadData: loadPeople } = useFetch(badgesService.allPeople, []);

  // Fetches people as soon as the hook is initialized.
  React.useEffect(() => {
    loadPeople();
  }, [loadPeople]);

  return {
    isLoaded,
    people,
  };
}
