import React from 'react';
import { Badge, Award } from '@packages/common';
import { BadgesService } from '../services/BadgesService';
import useFetch from './useFetch';

const badgesService = new BadgesService();

export interface AchievementsHooks {
  isLoaded: boolean;
  badges: Badge[];
  awards: Award[];
}

export default function useAchievements(person: string): AchievementsHooks {
  const { isLoaded, data, loadData: loadAchievements } = useFetch(() => badgesService.allAchievements(person), {
    badges: [],
    awards: [],
  });
  const { badges, awards } = data;

  // Fetches badges and awards as soon as the hook is initialized.
  React.useEffect(() => {
    loadAchievements();
  }, [loadAchievements]);

  return {
    isLoaded,
    badges,
    awards,
  };
}
