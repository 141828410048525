import { Badge, RootAPI, Person, Award } from '@packages/common';

const LOCAL = window.location.host.includes('localhost');
const BASE_URL = LOCAL ? `http://localhost:4000` : `https://badges-api.highbond-s1.com`;

export class BadgesService {
  async allPeople(): Promise<Person[]> {
    const response = await fetch(`${BASE_URL}/`);
    const json: RootAPI['/']['GET']['body'] = await response.json();
    return json.people;
  }

  // Conveniently request everything for now
  async allAchievements(person: string): Promise<{ badges: Badge[]; awards: Award[] }> {
    const response = await fetch(`${BASE_URL}/person/${person}`);
    const json: RootAPI['/person/:name']['GET']['body'] = await response.json();
    return json;
  }
}
