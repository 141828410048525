import React from 'react';

export interface FetchResult<T> {
  isLoaded: boolean;
  data: T;
  loadData: () => Promise<void>;
}

/**
 * Handles logic to set the loading state and save the resulting data
 * from an asynchronous data call.
 * @param fetchData A function that fetches data asynchronously
 */
export default function useFetch<T>(fetchData: () => Promise<T>, initialData: T): FetchResult<T> {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [data, setData] = React.useState<T>(initialData);

  const loadData = React.useCallback(async () => {
    setIsLoaded(false);
    setData(await fetchData());
    setIsLoaded(true);
  }, []);

  return {
    isLoaded,
    data,
    loadData,
  };
}
