import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useParams } from 'react-router-dom';
import Avatar from '@paprika/avatar';
import Spinner from '@paprika/spinner';
import Heading from '@paprika/heading';
import Academy from 'wasabicons/lib/Academy';
import StarFilled from 'wasabicons/lib/StarFilled';
import { usePeople, useAchievements } from './hooks';
import './App.scss';

const colors = ['#00AA55', '#009FD4', '#B381B3', '#939393', '#E3BC00', '#D47500', '#DC2A2A'];

function App(): JSX.Element {
  const { isLoaded, people } = usePeople();

  const peopleList = people.map(p => {
    const firstLetter = p.Employee.charAt(0);
    const firstCode = firstLetter.charCodeAt(0);
    const color = colors[firstCode % colors.length];

    const badgeDots = [...Array(p.BadgeCount).keys()].map(i => {
      return <Academy key={`badge-dot-${i}`} className="dot-badge" />;
    });

    const awardDots = [...Array(p.AwardCount).keys()].map(i => {
      return <StarFilled key={`award-dot-${i}`} className="dot-award" />;
    });

    return (
      <div className="people-list" key={`${p.EmployeeSlug}`}>
        <Avatar backgroundColor={color} color="white">
          {firstLetter}
        </Avatar>
        <Link className="people-list__link" to={`/person/${p.EmployeeSlug}`}>
          {p.Employee}
        </Link>
        {awardDots}
        {badgeDots}
      </div>
    );
  });

  return (
    <main className="main-container">
      <div className="main-container__content">
        <Router>
          <Switch>
            <Route exact path="/">
              <h1 className="page-title">Galvanites</h1>
              <div className="people-list-container">
                {isLoaded ? peopleList : <Spinner caption="Loading our Galvanites via VPN... ⏳" size="large" />}
              </div>
              <h1 className="page-title">Guidelines</h1>
              <p>
                <b>A VPN is required to view the badge data. If you are stuck watching the spinner, please turn on your VPN.</b>
              </p>
              <p>
                If you want to learn more about the Galvanize Experience and how to earn a badge or award, please refer
                to the README of <a href="https://github.com/acl-services/galvanize-experience">galvanize-experience</a>{' '}
                repository.
              </p>
            </Route>
            <Route path="/person/:slug">
              <Profile />
            </Route>
          </Switch>
        </Router>
      </div>
    </main>
  );
}

function Profile(): JSX.Element {
  const { slug } = useParams();
  const { isLoaded, badges, awards } = useAchievements(slug);

  const name = isLoaded && (badges[0] ?? awards[0]).Employee;

  const badgeEntries = badges.map(badge => {
    const year = new Date(badge.IssueDate).getUTCFullYear();
    return (
      <div className="badge" key={`badge-${badge.EmployeeSlug}-${badge.Module}`}>
        <img className="badge__image" src={`/assets/badges/${badge.Module}.png`} alt={badge.Module} />
        <div className="badge__issue-date">{year}</div>
      </div>
    );
  });

  const awardEntries = awards.map(award => {
    const year = new Date(award.IssueDate).getUTCFullYear();
    const file = `${award.Category}-${award.Achievement}.png`.toLowerCase().replace(/\s+/g, '-');
    return (
      <div className="badge" key={`award-${award.EmployeeSlug}-${award.Category}-${award.Achievement}`}>
        <img className="badge__image" src={`/assets/awards/${file}`} alt={award.Achievement} />
        <div className="badge__issue-date">{year}</div>
      </div>
    );
  });

  if (!isLoaded) {
    return (
      <>
        <h1 className="page-title" />
        <Spinner caption="Loading Achievements..." size="large" />
      </>
    );
  }

  return (
    <>
      <h1 className="page-title">{name}</h1>
      <Heading level={2}>Training Badges</Heading>
      {badges.length > 0 ? badgeEntries : <p>No earned training badge.</p>}
      <Heading level={2}>Trophies / Awards</Heading>
      {awards.length > 0 ? awardEntries : <p>No earned awards.</p>}
      <Heading level={2}>Kudos</Heading>
      <p>Experimental: Moosetrail praises (coming eventually).</p>
    </>
  );
}

export default App;
